<template>
  <div class="CmsNews">
    <div class="subject">
      <p v-if="!id" class="title">お知らせ - 管理</p>
      <p v-if="id" class="title">お知らせ - 編集</p>
    </div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="whiteArea">

        <v-form-calendar-datetime
          class="p-0 mb-3"
          iconClass="news-calendar-datetime-icon"
          label="掲載開始日"
          ref="from"
          selectWrapSize="0"
          v-model="from"
          :isRequired="true"
          @change="updateFrom"
          :errMessage="errors.from || []"
          inputClass="pickerDateForm"/>
        <hr>
        <b-row class="p-0 mb-2">
          <b-col sm="3">
          </b-col>
          <b-col class="pl-0">
            <b-form-checkbox class="bold w-220px" v-model="setDataTo">掲載終了日を設定する</b-form-checkbox>
          </b-col>
        </b-row>
        <v-form-calendar-datetime
          ref="to"
          class="p-0 mb-3"
          iconClass="news-calendar-datetime-icon"
          label="掲載終了日"
          selectWrapSize="0"
          v-model="to"
          :isRequired="true"
          :class="!setDataTo && 'grayscale'"
          :disabled="!setDataTo"
          @change="updateTo"
          :errMessage="errors.to || []"
          inputClass="pickerDateForm"/>
        <hr>

        <v-form-textarea
          label="本文"
          class="d-flex"
          placeholder="入力してください"
          labelSize="col-sm-2 p-0"
          maxlength="500"
          :isRequired="true"
          :value="news"
          @input="updateNews"
          :errMessage="errors.news || []"/>
      </div>

      <div
        v-if=!id
        class="bottonArea my-4 flex flexCenter">
        <b-button class="btn btn-lg mr-4 bold" to="/cms/top">トップに戻る</b-button>
        <b-button
          variant="info"
          class="btn btn-primary btn-lg bold"
          @click="store"
          :disabled="!isSend"
        >登録</b-button>
      </div>

      <div
        v-if=id
        class="bottonArea my-4 flex flexCenter">
        <b-button
          class="btn btn-lg mr-4 bold"
          @click="backToList"
        >一覧に戻る</b-button>
        <b-button
          variant="info"
          class="btn btn-primary btn-lg mr-4 bold"
          @click="store"
          :disabled="!isSend"
        >登録</b-button>
        <b-button
          @click="destroy"
          class="btn btn-dark btn-lg bold"
        >削除</b-button>
      </div>

      <b-table
        v-show=!id
        id='fieldTable'
        class='tableHeight text-left'
        show-empty
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        table-class="icon-white"
        no-border-collapse
        :items="newsList"
        :fields="fields">
        <template v-slot:empty="scope">
          <p class="none-data">お知らせはありません。</p>
        </template>

        <template v-slot:cell(delete)="row">
          <span class="edit" @click="edit(row.item)">編集</span>
        </template>
      </b-table>

    </div>
  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'CmsNews',
  data() {
    return {
      newsList: [],
      sortBy: 'age',
      sortDesc: false,
      fields: [
        { key: 'dataFrom', label: '掲載開始日', sortable: true },
        { key: 'dataTo', label: '掲載終了日', sortable: true },
        { key: 'news', label: '本文', sortable: false },
        { key: 'delete', label: '' },
      ],
    };
  },
  computed: {
    id: {
      get() {
        return this.$store.state.cmsNews.inputData.id;
      },
    },
    from: {
      get() {
        return this.$store.state.cmsNews.inputData.from;
      },
    },
    to: {
      get() {
        return this.$store.state.cmsNews.inputData.to;
      },
    },
    news: {
      get() {
        return this.$store.state.cmsNews.inputData.news;
      },
    },
    setDataTo: {
      get() {
        return this.$store.state.cmsNews.inputData.setDataTo;
      },
      set(value) {
        this.$store.commit('cmsNews/isSetDataTo', value);
        this.$store.dispatch('cmsNews/checkMarks');
        if (!value) {
          this.$refs.to.init();
        }
      },
    },
    isSend() {
      return this.$store.state.cmsNews.isSend;
    },
    errors() {
      return this.$store.state.cmsNews.errors;
    },
  },
  methods: {
    async list() {
      const res = await api.send('/api/news').catch(() => false);
      this.newsList = res.data.news;
      this.$store.dispatch('page/offLoading');
    },
    // 登録、更新
    async store() {
      this.$store.dispatch('page/onWaiting');
      const params = this.$store.state.cmsNews.inputData;
      const res = await api
        .send('/api/news/store', params)
        .catch((error) => {
          this.$store.commit('cmsNews/setErrorMsg', error.response.data.message);
          return false;
        });
      if (res !== false) {
        await this.alert('登録が完了しました。', false);
        this.$store.dispatch('cmsNews/resetErrorMsg');
        this.$store.dispatch('cmsNews/init', params);
        this.$refs.from.setData(this.from);
        this.$refs.to.init();
        this.$store.dispatch('cmsNews/checkMarks');
        this.list();
      }
      window.scrollTo(0, 0);
      this.$store.dispatch('page/offWaiting');
    },
    // 編集、戻る、削除
    edit(item) {
      this.$refs.to.setData(item.dataTo);
      this.$refs.from.setData(item.dataFrom);
      this.$store.dispatch('cmsNews/init');
      this.$store.dispatch('cmsNews/editNews', { item });
    },
    backToList() {
      this.$store.dispatch('cmsNews/init');
      this.$store.dispatch('cmsNews/resetErrorMsg');
      this.$store.dispatch('cmsNews/checkMarks');
    },
    async destroy() {
      this.$store.dispatch('page/onWaiting');
      const res = await api
        .send('/api/news/destroy', { delete_id: this.id })
        .catch(() => false);
      if (res !== false) {
        this.list();
        this.backToList();
        await this.alert('削除が完了しました。', false);
        window.scrollTo(0, 0);
      }
      this.$store.dispatch('page/offWaiting');
    },
    // 子コンポーネントの値受け
    updateFrom(param) {
      this.$store.commit('cmsNews/updateFrom', param.value);
      this.$store.dispatch('cmsNews/checkMarks');
    },
    updateTo(param) {
      this.$store.commit('cmsNews/updateTo', param.value);
      this.$store.dispatch('cmsNews/checkMarks');
    },
    updateNews(value) {
      this.$store.commit('cmsNews/updateNews', value);
      this.$store.dispatch('cmsNews/checkMarks');
    },
  },
  // ロード画面
  created() {
    this.$store.dispatch('page/onLoading');
    this.$store.dispatch('cmsNews/init');
    this.$store.dispatch('cmsNews/resetErrorMsg');
    this.list();
  },
};
</script>

<style scoped>
  .contentsWidth {
    width: 1080px;
    padding: 0;
    font-size: 16px;
  }
  .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }
  .title {
    margin-bottom: 0;
  }
  .whiteArea {
    background: #FFF;
    padding: 3rem;
  }
  .none-data {
    margin:0;
    text-align: center;
  }
</style>
<style>
  .CmsNews label {
    color: #333;
    font-weight: 600;
  }
  .CmsNews .vdp-datepicker {
    width: 220px;
  }
  .CmsNews .pickerDateForm {
    height: 50px;
    font-size: 16px;
  }
  .CmsNews .pickerDateForm::-ms-clear {
    visibility:hidden !important;
  }
  .CmsNews .datetime.custom-select {
    max-width: 90px;
    height: 50px;
    font-size: 16px;
    border-radius: 5px;
  }
  .CmsNews .calendar-icon {
    transform: translateY(50%);
  }
  .CmsNews .event {
    top: 50%;
    transform: translateY(-50%);
  }
  .CmsNews .vdp-datepicker ~ div {
    margin: 0 10px;
    font-size: 16px;
  }
  .CmsNews .grayscale input,
  .CmsNews .grayscale select {
    color: #F3F3F3;
    background: #F3F3F3;
  }
  .CmsNews .grayscale,
  .CmsNews .grayscale label {
    color: #3333334d
  }
  .CmsNews textarea {
    width: 700px;
    font-size: 16px;
    margin-left: 33px;
  }
  .CmsNews input[type="checkbox"] + label {
    min-height: 1.5rem;
    display: flex;
    align-items: center;
  }
  .CmsNews tbody {
    background: #FFF;
  }
  .CmsNews .tableHead th:nth-child(3) {
    width: 100%;
  }
  .CmsNews .dataWrap td:not(:nth-child(3)) {
    white-space: nowrap;
  }
  .CmsNews .edit {
    color: #0A8EA7;
    cursor: pointer;
  }
  .CmsNews .error {
    transform: translate(25%, 15px);
    padding-left: 0;
  }
  .w-220px {
    width: 220px;
  }
</style>
